@import url(https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.MuiOutlinedInput-notchedOutline {
  /* margin-top: 3px !important; */
  top: 0 !important;
}

.MuiOutlinedInput-notchedOutline legend {
  height: 0;
}

/**
 * Main wrapper
 */
.select-search {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
  * Value wrapper
  */
.select-search__value {
  /* position: relative; */
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
  * Input
  */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  /* box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); */
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.MuiSelect-select.MuiSelect-outlined {
  border: unset;
}

.MuiSelect-root .MuiSelect-notchedOutline {
  border-color: #0000001f !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #ffffff !important;
}

.MuiSelect-input {
  color: rgba(0, 0, 0, 0.6) !important;
}

.MuiSelect-root.Mui-focused .MuiSelect-input {
  color: rgba(0, 0, 0, 0.6) !important;
}

.MuiInputLabel-outlined {
  color: rgba(0, 0, 0, 0.6) !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #fff;
}

.MuiMenuItem-root {
  font-size: 14px !important;
}

.ant-select-dropdown {
  z-index: 100000 !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
  * Options wrapper
  */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
  * Options
  */
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
  * Option row
  */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
  * Option
  */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  color: #fff;
}

/**
  * Group
  */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
  * States
  */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
  * Modifiers
  */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

/* style-filter-select-search */
.style-filter-select-search-wrap.indexBelowSidebar {
  z-index: 1999 !important;
}

.style-filter-select-search {
  margin-top: 4px;
  border-radius: 4px !important;
  mix-blend-mode: multiply;
  background-blend-mode: multiply;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2) !important;
  background: linear-gradient(to bottom, #fff, #fff);
  height: auto;
}

.style-filter-select-search .MuiAutocomplete-option {
  padding: 0.5rem 1.5rem !important;
  color: #29302e;
}

.style-filter-select-search .MuiAutocomplete-option[aria-selected='true'],
.style-filter-select-search .MuiMenuItem-root.Mui-selected {
  background-color: transparent !important;
}

.Mui-multiple .MuiAutocomplete-option.Mui-focused {
  background-color: transparent !important;
  color: #29302e;
}

/* basic select */
.style-basic-select {
  max-height: 300px !important;
}

.style-filter-select-search .MuiMenuItem-root {
  font-size: 16px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 6px 16px !important;
  background-color: #fff !important;
}

.style-select-teeth {
  max-height: 200px !important;
}

.style-select-doctors {
  max-height: 200px !important;
  /* max-width: 160px !important; */
}

.style-select-doctors-mb {
  max-height: 200px !important;
  /* max-width: 240px !important; */
}

.month-indicator {
  color: var(--blue-grey-700);
  text-align: center;
  font-weight: 500;
}

.day-of-week,
.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week {
  margin-top: 1.25em;
}

.day-of-week > * {
  font-size: 1.1vw;
  font-size: 0.7em;
  color: var(--blue-grey-400);
  font-weight: 500;
  letter-spacing: 0.1em;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-align: center;
}

/* Dates */
.date-grid {
  margin-top: 0.5em;
}

/* Positioning the first day */
.date-grid button:first-child {
  grid-column: 6;
}

.date-grid button {
  position: relative;
  border: 0;
  width: 4.5ch;
  height: 4.5ch;
  border-radius: 50%;
  background-color: transparent;
  color: var(--blue-grey-600);
}

.date-grid button:active,
.date-grid button.is-selected {
  background-color: var(--teal-100);
  color: var(--teal-900);
}

.calendar-root {
  --grid-gap: 2px;
  --grid-height: auto;
  --text-color-light: rgba(0, 0, 0, 0.4);
  --text-color-primary: rgba(0, 0, 0, 0.7);
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
}

/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
  color: #2f4269;
  font-weight: 700;
  /* background-color: var(--grid-foreground-color); */
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* border: 1px solid black; */
  /* border-radius: 20px; */
  text-align: center;
  background-color: #d8e8f4;
}

.day-of-week-header-cell .weekend-day {
  border-radius: 20px;
}

.days-of-week {
  font-size: 1.1vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}
@media screen and (max-width: 767.5px) {
  .days-of-week {
    font-size: 1rem;
    grid-template-columns: repeat(7, 23vw);
  }
}

.days-of-week-edit {
  font-size: 1.1vw;
  margin-bottom: 16px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}

@media screen and (max-width: 767.5px) {
  .days-of-week-edit {
    font-size: 1rem;
    grid-template-columns: repeat(7, 23vw) !important;
  }
}

.day-of-week-header-cell-edit {
  /* color: #2f4269; */
  font-weight: 700;
  /* background-color: var(--grid-foreground-color); */
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
}

.days-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  /* border-radius: 20px 20px 0px 0px; */
  /* overflow: hidden; */
  height: var(--grid-height);
  position: relative;
  /* border: var(--grid-gap) solid var(--grid-background-color); */
  background-color: var(--grid-background-color);
}

@media screen and (max-width: 767.5px) {
  .days-grid {
    grid-template-columns: repeat(7, 23vw);
  }
}

.day-grid-item-container {
  background-color: white;
  height: 160px;
  /* overflow: hidden; */
  /* position: relative; */
  /* background-color: var(--grid-foreground-color); */
  border: 1px solid #d8e8f4;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
}
.day-grid-item-container:hover {
  overflow: visible;
}

.day-grid-item-container:not(.current-month) .day-grid-item-header {
  color: #9e9e9e;
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item-header {
  line-height: 19px;
  font-size: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: #29302e;
  /* padding-left: 8px;
  padding-bottom: 0; */
  flex-shrink: 0;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
}

.day-grid-item-container > .day-content-wrapper {
  flex: 1 1;
  min-height: 0;
  position: relative;
}

.navigation-header {
  display: flex;
}

.navigation-header > * {
  margin-right: var(--space-md);
}

.header-days {
  padding: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
}

.header-item {
  display: flex;
  align-items: baseline;
}

.trash-icon {
  cursor: pointer;
  margin-left: auto;
}

.container-item {
  height: 100%;
  padding: 8px 8px;
}
.body-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.container-item-edit {
  height: 100%;
  padding: 8px 8px;
}
.dentist-container {
  align-items: center;
  display: flex;
  cursor: pointer;
}

.img-container {
  width: 2vw;
  height: 2vw;
  background-color: black;
  border-radius: 50%;
  margin-right: 8px;
}
.text-container {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dentist-header {
  display: flex;
  align-items: center;
  margin: 8px 0px 4px 0px;
}
.dentist-name {
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dentist-time {
  font-size: 0.9vw;
  color: rgba(5, 5, 5, 0.6);
  margin-left: 4px;
}

.more-time {
  font-size: 0.9vw;
  text-align: end;
}
.dropdown-all-dentist {
  left: 0;
  display: none;
  position: absolute;
  background-color: #ffffff !important;
  width: 12.8vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.text-container-single {
  width: 100%;
}

.dentist-header-single {
  display: flex;
  align-items: flex-start;
  margin: 8px 0px 4px 0px;
}
.dentist-name-single {
  width: 80%;
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dentist-time-single {
  font-size: 0.8vw;
  color: rgba(5, 5, 5, 0.6);
  margin-left: 4px;
}
.dentist-assistant {
  font-size: 0.8vw;
  display: flex;
  margin-left: 4px;
}
.title-assistant {
  white-space: nowrap;
  margin-right: 2px;
}

.worng-duty {
  font-size: 0.9vw;
  color: #e2a706;
}

.cancel-duty {
  font-size: 0.9vw;
  color: #fb5252;
}

.item-worng-duty {
  background-color: aqua;
}
.item-cancel-duty {
  background-color: aqua;
}
.icon-time {
  margin-right: 6px;
}
.icon-assistant {
  margin-right: 5px;
}
.icon-bank {
  margin-right: 6px;
}

.dentist-cost-condition {
  font-size: 0.8vw;
  margin-left: 4px;
}

.container-item-worng-duty {
  align-items: center;
  justify-items: center;
  height: 100%;
  background-color: rgba(251, 82, 82, 0.1);
  padding: 8px 8px;
}

.dentist-container-worng-duty {
  font-size: 18px;
  margin-top: 32px;
  height: auto;
  text-align: center;
}

.container-item-cancel-duty {
  align-items: center;
  justify-items: center;
  height: 100%;
  background-color: rgba(226, 167, 6, 0.1);
  padding: 8px 8px;
}

.dentist-container-cancel-duty {
  font-size: 18px;
  margin-top: 32px;
  height: auto;
  text-align: center;
}

.input .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 32px;
  background-color: #fff;
}

.closeButton {
  position: absolute;
  top: 20;
  right: 20;
}

.modal .modal-content {
  padding: 15;
  border-radius: 10;
  position: relative;
}

.text-container-modal {
  width: 80%;
}

.date-model {
  font-size: 1.1vw;
  font-weight: 700;
  margin-bottom: 0.6vw;
}
.dentist-header-modal {
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  margin: 8px 0px 4px 0px;
}
.dentist-name-modal {
  width: 80%;
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
}
.dentist-time-modal {
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 4px;
  /* text-overflow: ellipsis;
  overflow: hidden; */
}
.dentist-assistant-modal {
  font-size: 1vw;
  display: flex;
  margin-left: 4px;
}

.title-assistant-modal {
  /* width: 40%; */
  white-space: nowrap;
  margin-right: 2px;
}
.dentist-cost-condition-modal {
  font-size: 1vw;
  margin-left: 4px;
}

.input-cost-condition {
  border-radius: 4px;
  /* border: solid 1px rgba(0, 0, 0, 0.6); */
  height: 32px;
  font-size: 14px !important;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.6);
}

/* .input-cost-condition .css-a88p61-MuiInputBase-root-MuiInput-root {
  font-size: 16px;
} */

.input-cost-condition::after {
  display: none;
}

.input-cost-condition::before {
  display: none;
}

.dentist-container-modal {
  align-items: center;
  display: flex;
}
.img-container-modal {
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 0.5vw;
  align-self: flex-start;
}
.name-assistant-modal {
  display: inline-block;
}
#contained-modal-title-vcenter {
  font-weight: 700;
  font-size: 18px;
  color: #2f4269;
}

body {
  font-family: 'Sarabun', sans-serif;
  font-size: 10px;
  -webkit-print-color-adjust: exact;
  margin: 0;
  color: #29302e;
}
p {
  margin: 0;
}
.mockup-a4-landscape {
  width: 842px;
  height: 695px;
  margin: auto;
}

.grid-item {
  display: grid;
  grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
  align-items: center;
}
.day {
  border: 1px solid;
  height: 90px;
  padding: 2px;
  overflow: hidden;
}
.day-text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.day-number {
  float: right;
  font-size: 16px;
  font-weight: 500;
}

.form-switch .form-check-input {
  height: 25px !important;
  width: 48px !important;
  cursor: pointer;
  background-color: #9e9e9e !important;
  border-color: #9e9e9e !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:checked {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.form-switch .form-check-input:focus {
  box-shadow: none !important;
}

.form-switch .form-check-input:focus:not(:checked) {
  border-color: #9e9e9e !important;
}

.month-indicator {
  color: var(--blue-grey-700);
  text-align: center;
  font-weight: 500;
}

.day-of-week,
.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week {
  margin-top: 1.25em;
}

.day-of-week > * {
  font-size: 0.7em;
  color: var(--blue-grey-400);
  font-weight: 500;
  letter-spacing: 0.1em;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-align: center;
}

/* Dates */
.date-grid {
  margin-top: 0.5em;
}

/* Positioning the first day */
.date-grid button:first-child {
  grid-column: 6;
}

.date-grid button {
  position: relative;
  border: 0;
  width: 4.5ch;
  height: 4.5ch;
  border-radius: 50%;
  background-color: transparent;
  color: var(--blue-grey-600);
}

/* .date-grid button:hover,
  .date-grid button:focus {
    outline: none;
    background-color: var(--blue-grey-050);
    color: var(--blue-grey-700);
  } */

.date-grid button:active,
.date-grid button.is-selected {
  background-color: var(--teal-100);
  color: var(--teal-900);
}

.calendar-root {
  --grid-gap: 2px;
  --grid-height: auto;
  --text-color-light: rgba(0, 0, 0, 0.4);
  --text-color-primary: rgba(0, 0, 0, 0.7);
  /* --grid-background-color: rgb(211, 205, 198);
    --grid-foreground-color: white; */
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
}

/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
  color: #2f4269;
  font-weight: 700;
  /* background-color: var(--grid-foreground-color); */
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* border: 1px solid black; */
  /* border-radius: 20px; */
  text-align: center;
  background-color: #d8e8f4;
}

.day-of-week-header-cell .weekend-day {
  border-radius: 20px;
}

.days-of-week-day {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}

.days-of-week-edit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}

.day-of-week-header-cell-edit {
  /* color: #2f4269; */
  font-weight: 700;
  /* background-color: var(--grid-foreground-color); */
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
}

.days-grid-week {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  /* border-radius: 20px 20px 0px 0px; */
  /* overflow: hidden; */
}

.days-grid {
  height: var(--grid-height);
  position: relative;
  /* border: var(--grid-gap) solid var(--grid-background-color); */
  background-color: var(--grid-background-color);
}

.day-grid-item-container {
  background-color: white;
  height: 100%;
  min-height: 160px;
  /* overflow: hidden; */
  /* position: relative; */
  /* background-color: var(--grid-foreground-color); */
  border: 1px solid #d8e8f4;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
}
.day-grid-item-container:hover {
  overflow: visible;
}

.day-grid-item-container:not(.current-month) .day-grid-item-header {
  color: #9e9e9e;
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item-header {
  font-size: 16px;
  width: 24px;
  height: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: #29302e;
  /* padding-left: 8px;
    padding-bottom: 0; */
  flex-shrink: 0;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
}

.day-grid-item-container > .day-content-wrapper {
  flex: 1 1;
  min-height: 0;
  position: relative;
}

.navigation-header {
  display: flex;
}

.navigation-header > * {
  margin-right: var(--space-md);
}

.header-days {
  padding: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
}

.header-item {
  display: flex;
  align-items: baseline;
}

.trash-icon {
  margin-left: auto;
}

.container-item {
  position: relative;
  height: 100%;
  padding: 8px 8px;
}
.container-item-edit {
  height: 100%;
  padding: 8px 8px;
}
.dentist-container {
  align-items: center;
  display: flex;
  /* margin-left: 12px;
    margin-right: 12px; */
}

.icon-card-dentist-time-name {
  width: 15%;
  height: 26px;
  margin-right: 7px;
  background-color: #243b53;
  text-align: center;
  line-height: 1;
}

.tab-dentist-container {
  position: relative;
  margin-top: 4px;
  border: 1px solid #e5e5e5;
  align-items: center;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}

.dentist-time-name {
  font-size: 0.9vw;
  color: #000;
  font-weight: 700;

  white-space: nowrap;
}
.card-dentist-time-name {
  display: flex;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-container {
  width: 2vw;
  height: 2vw;
  background-color: black;
  border-radius: 50%;
  margin-right: 8px;
}
.text-container {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dentist-header {
  display: flex;
  align-items: center;
  margin: 8px 0px 4px 0px;
}
.dentist-name {
  font-size: 0.9vw;
  color: #2f4269;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dentist-time {
  font-size: 0.9vw;
  color: rgba(5, 5, 5, 0.6);
  margin-left: 4px;
  /* text-overflow: ellipsis;
    overflow: hidden; */
}

.more-time {
  font-size: 0.9vw;
  text-align: center;
}

.dropdown-all-dentist {
  left: 0;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 12.8vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.text-container-single {
  width: 100%;
}

.dentist-header-single {
  display: flex;
  align-items: flex-start;
  margin: 8px 0px 4px 0px;
}
.dentist-name-single {
  width: 80%;
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
}

.dentist-time-single {
  font-size: 0.8vw;
  color: rgba(5, 5, 5, 0.6);
  margin-left: 4px;
  /* text-overflow: ellipsis;
    overflow: hidden; */
}

.dentist-assistant {
  font-size: 0.8vw;
  display: flex;
  margin-left: 4px;
}

.title-assistant {
  width: 40%;
  margin-right: 2px;
}

.worng-duty {
  font-size: 0.9vw;
  color: #e2a706;
}

.cancel-duty {
  font-size: 0.9vw;
  color: #fb5252;
}

.item-worng-duty {
  background-color: aqua;
}
.item-cancel-duty {
  background-color: aqua;
}
.icon-time {
  margin-right: 6px;
}
.icon-assistant {
  margin-right: 5px;
}
.icon-bank {
  margin-right: 6px;
}

.dentist-cost-condition {
  font-size: 0.8vw;
  margin-left: 4px;
}

.container-item-worng-duty {
  align-items: center;
  justify-items: center;
  height: 100%;
  background-color: rgba(251, 82, 82, 0.1);
}

.dentist-container-worng-duty {
  margin-top: 40px;
  height: auto;
  text-align: center;
}

.container-item-cancel-duty {
  align-items: center;
  justify-items: center;
  height: 100%;
  background-color: rgba(226, 167, 6, 0.1);
}

.dentist-container-cancel-duty {
  margin-top: 40px;
  height: auto;
  text-align: center;
}

.input .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 32px;
  background-color: #fff;
}

.closeButton {
  position: absolute;
  top: 20;
  right: 20;
}

.modal .modal-content {
  padding: 15;
  border-radius: 10;
  position: relative;
}

.text-container-modal {
  width: 80%;
}

.date-model {
  font-size: 1.1vw;
  font-weight: 700;
  margin-bottom: 0.6vw;
}
.dentist-header-modal {
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  margin: 8px 0px 4px 0px;
}
.dentist-name-modal {
  width: 80%;
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
}
.dentist-time-modal {
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 4px;
  /* text-overflow: ellipsis;
    overflow: hidden; */
}
.dentist-assistant-modal {
  font-size: 1vw;
  display: flex;
  margin-left: 4px;
}

.title-assistant-modal {
  width: 40%;
  margin-right: 2px;
}
.dentist-cost-condition-modal {
  font-size: 1vw;
  margin-left: 4px;
}

.dentist-container-modal {
  align-items: center;
  display: flex;
}
.img-container-modal {
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 0.5vw;
  align-self: flex-start;
}
.name-assistant-modal {
  display: inline-block;
}
#contained-modal-title-vcenter {
  font-weight: 700;
  font-size: 18px;
  color: #2f4269;
}

.container-calendar {
  display: flex;
}

.popup-info-tab {
  padding: 18px 22px;
  position: absolute;
  width: 25vw;
  height: auto;
  background: transparent linear-gradient(180deg, #ffffff87 0%, #f2f2f257 100%) 0% 0% no-repeat padding-box;
  border-top: 10px;
  border: 1px solid #f3f1f1;
  opacity: 1;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  line-height: 1.6vw;
}
.popup-info-tab:after {
  background: red;
  position: absolute;
  content: '';
  height: 4px;
  right: 0;
  left: 0;
  top: 0;
}

.popup-info {
  width: auto;
  height: auto;
}

.modal-cancel-visit .modal-content {
    max-width: 543px;
    margin: auto;
}

.modal-send-line-message .modal-dialog {
    max-width: 1140px;
    margin: auto;
}

@media screen and (min-width: 1200px) {
    .modal-send-line-message .modal-dialog .modal-content {
        max-height: calc(100% - 5rem);
    }
}

@media screen and (max-width: 767.5px) {
    .modal-send-line-message .modal-body {
        margin-bottom: 0 !important;
    }

    .modal-send-line-message .modal-footer {
        display: none !important;
    }
}

.modal-xray .modal-dialog{
    margin: auto;
    padding: 1rem 3.2rem !important;
}
.modal-form-appointment .modal-xl .modal-footer {
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (min-width: 768px) {
  .modal-form-appointment .modal-xl .modal-footer {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 992px) {
  .modal-form-appointment .modal-xl {
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .modal-form-appointment .modal-xl {
    max-width: 1366px;
  }
}

.pop-edit-auth .modal-dialog-centered {
  min-height: calc(100% - 50%) !important;
}

.pop-edit-auth .modal-footer {
  margin: 0 !important;
}

.modal-product-preserves .modal-dialog {
    max-width: 320px;
}
.pd-body {
  padding: 10px;
  height: 220px;
  border-radius: 5px;
  overflow: scroll;
  scrollbar-width: thin;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
}

.pd-body::-webkit-scrollbar {
  display: none;
}

.pd-body-unit {
  overflow: hidden;
  height: 198px;
  width: 120px;
  float: left;
  margin-right: 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.unit-img-contain {
  width: 100%;
  height: 85%;
  margin-bottom: 4px;
  position: relative;
}

.unit-img-bg-text {
  width: 100%;
  background-color: #21252996;
  color: #fff;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  bottom: 1px;
  font-size: 14px;
  padding: 0px 5px;
}

.unit-card-title-img {
  background-color: aqua;
  bottom: 7px;
  left: 50%;
  /* left: 16px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.875rem;
  position: absolute;
}

.unit-img-contain .unit-img {
  width: 100%;
  height: 100%;
}

.original-price {
  padding: 0px 5px;
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.add-icon {
  cursor: pointer;
  margin-left: auto;
}

.delete-icon {
  color: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .pd-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 300px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: scroll;
  }

  .fs-body-unit {
    width: 100px;
    height: auto;
    margin-right: 0px;
  }

  .unit-img-contain {
    width: 100px;
    height: 100px;
  }

  .unit-img-contain .unit-img {
    width: 100px;
    height: 100px;
  }

  .unit-card-original-price {
    font-size: 10px;
  }
}

.modal-treatment-plan-types .modal-dialog {
    max-width: 330px;
}
.add-icon {
  cursor: pointer;
  margin-left: auto;
}

.delete-icon {
  color: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-left: auto;
}

.toothChart-operative-tooltip .MuiTooltip-tooltip {
  margin-left: 0;
  margin-bottom: 4px !important;
  background-color: #fff;
  border: 1px solid #c5c5c5 !important;
  color: #29302e;
  border-radius: 0;
}
/* modal-treatment */
.modal-treatment .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  margin-bottom: 0 !important;
}

.modal-treatment .modal-dialog .modal-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-treatment .modal-dialog .modal-content .modal-footer {
  margin-top: 0 !important;
}

.modal-treatment.modal-treatment-info .modal-dialog .modal-content {
  padding-bottom: 0 !important;
}

/* modal-treatment-course */
.modal-treatment-course .modal-content {
  max-width: 1149px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden !important;
}

.modal-treatment-course.disabled-modal-footer .modal-footer button {
  border-color: #9e9e9e !important;
  color: #9e9e9e !important;
  pointer-events: none;
}

.modal-treatment-course.disabled-modal-footer .modal-footer button.btn-submit {
  background-color: #9e9e9e !important;
  color: #fff !important;
}

/* modal-next-visit */
.modal-next-visit .modal-content {
  max-width: 1072px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden !important;
}

.modal-next-visit .modal-content .modal-footer {
  display: none !important;
}

/* modal-med-certificate */
.modal-med-certificate .modal-fullscreen {
  height: auto !important;
}

.modal-med-certificate .modal-content {
  max-width: 1040px;
  margin: auto;
  height: auto !important;
}

.modal-med-certificate .modal-content table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}

/* modal-alert-out-of-stock */
.modal-alert-out-of-stock .modal-content {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

/* modal-treatment-consent */
.modal-treatment-consent .modal-content .modal-footer {
  margin-top: 0 !important;
}

/* modal-treatment-message */
.modal-treatment-message .modal-dialog .modal-content .modal-body {
  margin-bottom: 24px;
}

/* modal-treatment */
/* modal-treatment-course */
/* modal-treatment-message */
.modal-treatment-course .modal-dialog .modal-content .modal-body,
.modal-treatment-message .modal-dialog .modal-content .modal-body,
.modal-med-certificate .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

/* modal-treatment */
/* modal-treatment-message */
/* modal-next-visit */
.modal-treatment .modal-dialog .modal-content,
.modal-treatment-message .modal-content,
.modal-next-visit .modal-content {
  overflow: hidden !important;
}

/* modal-emr-overview */
.modal-emr-overview .modal-dialog {
  min-height: 100%;
  width: 100%;
  max-width: unset;
  padding: 1rem;
  margin: auto;
}

.modal-emr-overview .modal-content {
  max-width: 1512px;
  margin: auto;
}

.modal-alert-out-of-stock .modal-content .modal-footer,
.modal-emr-overview .modal-content .modal-footer {
  margin-top: 0 !important;
}

/* modal-txc */
.modal-txc .modal-content {
  max-width: 1600px;
  margin: auto;
}

.modal-txc .modal-body {
  overflow-x: hidden !important;
}

/* popup-manage-product  */
.popup-manage-product .modal-dialog {
  max-width: 1235px;
}

/* recommend */
.modal-recommend .modal-dialog .modal-content {
  max-width: 1700px;
  margin: auto;
}

/* 
modal-popup-select-product
popup-manage-product
*/
.popup-manage-product .modal-dialog .modal-content,
.modal-popup-select-product .modal-dialog .modal-content {
  margin: 1rem;
}

@media screen and (min-width: 1400px) {

  /* modal-treatment-message */
  .modal-treatment-message .modal-dialog .modal-content {
    min-height: 50%;
  }

  /* modal-treatment */
  /* modal-treatment-course */
  /* modal-treatment-message */
  .modal-treatment .modal-dialog,
  .modal-treatment-course .modal-dialog,
  .modal-treatment-message .modal-dialog {
    max-height: calc(100% - 3.5rem);
    height: 100%;
  }

  .modal-treatment .modal-dialog .modal-content,
  .modal-treatment-course .modal-dialog .modal-content,
  .modal-treatment-message .modal-dialog .modal-content {
    max-height: 100%;
  }
}

/* modal-pdf-referral-examination */
.modal-referral-examination .modal-content {
  max-width: 1140px;
  margin: auto;
}

@media (max-width: 1200px) {
  .modal-referral-examination .modal-content {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  .modal-referral-examination .modal-dialog {
    max-width: 650px;
  }
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
.sidebar{
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
}
.sidebar.active{
    left: 0;
    z-index: 2000;
}
.sd-header{
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    justify-content: space-between;
    padding: 38px 15px 16px 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}

.btnCommission {
    min-width: 30px !important;
}

.modal-outstandingpayment .modal-footer {
    display: none;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: transparent;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: transparent;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: transparent;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 100%;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  margin: -1em !important;
  padding-left: 1em !important;
}

a.mr-3.p-0.w-100 {
  text-decoration: none !important;
}

.recall-modal .modal-content {
  max-width: 600px;
  margin: auto;
}

.modal-form-management {
  padding-left: 0 !important;
}

.modal-form-management .modal-content {
  max-width: 976px;
  margin: auto;
}

.modal-form-management .modal-content .modal-body {
  padding-top: 0;
}

.modal-form-management .modal-content .modal-body .row {
  margin-left: 0;
  margin-right: 0;
}

.modal-form-management .modal-content .modal-body .header-tab {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  border-radius: 4px;
}

.modal-form-management .modal-content .modal-body .row-menu {
  padding-left: 1rem;
  padding-right: 1rem;
}

.modal-form-management .modal-content .modal-body .row-menu:last-of-type {
  margin-bottom: -9px;
}
@media screen and (max-width: 1199.5px) {

    /* .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root {
        margin-left: 356px;
    } */

    .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root,
    .summary-layout-drawer .MuiPaper-root {
        top: 72px;
    }

    .summary-layout-drawer .MuiBackdrop-root {
        background-color: rgba(35, 31, 32, 0.3);
    }

    .summary-layout-drawer .MuiPaper-root {
        width: 100%;
        height: 100%;
        max-width: 320px;
        box-shadow: none;
    }
}

@media screen and (max-width: 899.5px) {

    /* .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root {
        margin-left: 340px;
    } */
}

@media screen and (max-width: 767.5px) {

    /* .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root {
        margin-left: 0;
    } */

    .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root,
    .summary-layout-drawer .MuiPaper-root {
        top: 56px;
    }
}

@media screen and (max-width: 320px) {
    .summary-layout-drawer .MuiPaper-root {
        max-width: unset;
    }
}
.modal-popup-follow-treatment .modal-dialog {
  max-width: 832px;
  margin: auto;
}

.modal-popup-follow-treatment .modal-dialog .modal-body {
  color: #29302e;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-popup-follow-treatment .modal-dialog .modal-body .MuiTableContainer-root {
  overflow: auto;
  min-height: unset !important;
  max-height: 100%;
}

.modal-popup-follow-treatment .modal-dialog .modal-body .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}

.modal-popup-follow-treatment .modal-dialog .modal-footer {
  margin-top: 0 !important;
}
.modal-receive-lab .modal-dialog {
  padding: 8px;
}

.modal-receive-lab .modal-content {
  max-width: 543px;
  margin-left: auto;
  margin-right: auto;
}

.modal-receive-lab .modal-content .modal-footer > div > div {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.modal-receive-lab .modal-content .modal-footer > div > div button {
  margin: 0 !important;
}

@media screen and (max-width: 576px) {
  .modal-receive-lab .modal-content .modal-footer > div {
    flex-direction: column;
  }

  .modal-receive-lab .modal-content .modal-footer > div > div {
    width: 100%;
  }

  .modal-receive-lab .modal-content .modal-footer > div > div:last-of-type {
    padding-top: 16px;
  }
}

.modal-confirm-appointment .modal-content {
  margin: auto;
}

.modal-confirm-appointment.modal-confirm-appointment-submit .modal-content {
  max-width: 418px;
}

.modal-confirm-appointment.modal-confirm-appointment-cancel .modal-content {
  max-width: 500px;
}
.promotion-del .swal2-warning{
  border-color: #FB5252 !important;
  color: #FB5252 !important;
}

.promotion-del-con {
  border-color: #FB5252 !important;
  background-color: #FB5252 !important;
}

.btn-check-in-swal {
  color: #1cb99a !important;
  border-color: #1cb99a !important ;
}

/* level 1 */
.container-menu .menu-first {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: linear-gradient(177deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  transition: max-width 0.3s ease;
  border: 1px solid #e5e5e5;
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 16px 0;
}

.container-menu .menu-first.show {
  z-index: 2000;
  border-top-right-radius: 0;
}

.container-menu .menu-first.show:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(171deg, rgba(255, 255, 255, 1) 1%, rgba(242, 242, 242, 1) 99%);
  backdrop-filter: saturate(1) blur(20px);
  -webkit-backdrop-filter: saturate(1) blur(20px);
  z-index: -1;
}

.menu-first .menu-first-level .flex-name {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.375rem 1.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
}

.menu-first .menu-first-level .flex-name.menu-first-arrow::after {
  content: 'arrow_right';
  font-family: 'Material Icons Round', sans-serif;
  margin-left: auto;
  margin-right: -8px;
  font-size: 22px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.menu-first.show .menu-first-level .flex-name.menu-first-arrow:hover::after,
.menu-first.show .menu-first-level .flex-name.menu-first-arrow.active::after {
  opacity: 1;
}

.menu-first .menu-first-level .flex-name.active .menu-first-text {
  font-weight: 600;
}

.menu-first .menu-first-level .menu-first-text {
  padding-left: 16px;
  display: none;
}

.menu-first .menu-first-level .menu-first-icon.active {
  position: relative;
  z-index: 0;
}

.menu-first .menu-first-level .menu-first-icon {
  background-color: transparent !important;
}

.menu-first .menu-first-level .menu-first-icon.active::before {
  content: '';
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  border-radius: 100px;
  z-index: -1;
  transition: background-color 0.3s ease;
}

.menu-first.show .menu-first-level .flex-name .menu-first-icon::before,
.menu-first .menu-first-level .flex-name:hover .menu-first-icon.active::before,
.menu-first .menu-first-level .flex-name.active .menu-first-icon.active::before {
  background-color: transparent;
}

.menu-first.show .menu-first-level .menu-first-text {
  display: block;
  color: #231f20;
}

/* level 2 */
.menu-second {
  display: none;
  position: absolute;
  /* top: auto; */
  /* bottom: 0; */
  left: 100%;
}

.menu-second.active {
  display: block;
}

.menu-second .menu-second-wrap {
  padding: 1rem 0 1.25rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(242, 242, 242, 1));
  backdrop-filter: saturate(1) blur(35px);
  -webkit-backdrop-filter: saturate(1) blur(35px);
  border: 1px solid #e5e5e5;
  border-radius: 0 0.5rem 0.5rem 0;
}

.menu-second .menu-second-level {
  position: relative;
}

.menu-second .menu-second-level .item-second-list {
  position: unset;
  margin: 0.25rem 0;
  padding: 0.5785rem 1rem;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  font-weight: 400 !important;
  cursor: pointer;
  color: #231f20;
}

.menu-second .menu-second-level .item-second-list svg {
  font-size: 24px;
}

.menu-second .menu-second-level .item-second-list[aria-expanded='true'] span {
  font-weight: 600;
}

/* level3 as Collapse */
.menu-third .menu-third-level {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-third .menu-third-level .item-third-list {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  margin: 0.25rem 0;
}

.menu-third .menu-third-level .item-third-list::before {
  content: 'fiber_manual_record';
  font-family: 'Material Icons', sans-serif !important;
  font-size: 11px;
  margin-right: 0.5rem;
}

.menu-first .menu-first-wrap {
  overflow-x: hidden;
}

.menu-first .menu-first-wrap,
.menu-second .menu-second-wrap {
  overflow-y: auto;
  scrollbar-width: thin;
}

.menu-first .menu-first-wrap::-webkit-scrollbar,
.menu-second .menu-second-wrap::-webkit-scrollbar {
  width: 0.25rem;
}

.menu-first .menu-first-wrap::-webkit-scrollbar-thumb,
.menu-second .menu-second-wrap::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 50px;
}

/* backdrop */
.sidebar-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #231f20;
  opacity: 0.3;
  z-index: 1999;
}

/* tooltip */
.customTooltip-sidebar[data-title]:before {
  content: attr(data-title);
  color: transparent !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  font-weight: 300;
  font-size: 14px;
  display: none;
  background-color: transparent;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

.customTooltip-sidebar[data-title]:after {
  content: attr(data-title);
  color: #fff !important;
  position: absolute;
  top: 100%;
  /* left: 0; */
  right: 0;
  /* width: max-content; */
  font-weight: 300;
  font-size: 14px;
  display: none;
  background-color: #444;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

.menu-second .menu-second-level button.item-second-list .customTooltip-sidebar[data-title]:after {
  top: 51px;
}

@media screen and (max-width: 1199.5px) {
  .customTooltip-sidebar[data-title]:after {
    display: none !important;
  }

  .container-menu .menu-first.show:before,
  .menu-second .menu-second-wrap {
    background: #f5f5f5;
  }
}

@media screen and (min-width: 1200px) {
  /* show tooltip */
  .menu-first .menu-first-level .flex-name:hover .customTooltip-sidebar[data-title]:before,
  .menu-second .menu-second-level button.item-second-list:hover .customTooltip-sidebar[data-title]:before,
  .menu-third .menu-third-level .item-third-list:hover .customTooltip-sidebar[data-title]:before,
  .menu-first .menu-first-level .flex-name:hover .customTooltip-sidebar[data-title]:after,
  .menu-second .menu-second-level button.item-second-list:hover .customTooltip-sidebar[data-title]:after,
  .menu-third .menu-third-level .item-third-list:hover .customTooltip-sidebar[data-title]:after {
    display: block;
  }
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  border-color: #29302e !important;
  background-color: #29302e !important;
}

.react-multi-carousel-dot button {
  border-color: #9e9e9e !important;
  background-color: #9e9e9e !important;
}

.react-multi-carousel-dot-list {
  margin-top: 50;
}

.react-multi-carousel-list {
  height: 170px;
}

.react-multi-carousel-track {
  padding: 0 0 30px 0 !important;
  width: 165px;
}

.react-multi-carousel-item {
  min-width: 160px;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(/static/media/Sarabun-Light.0f087e83.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(/static/media/Sarabun-Regular.6620546d.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(/static/media/Sarabun-Medium.1e54abd5.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(/static/media/Sarabun-SemiBold.398aadea.ttf) format('truetype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'Sarabun, sans-serif', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Sarabun, sans-serif', sans-serif !important;
  letter-spacing: 0.5px;
}

code {
  font-family: 'Sarabun, sans-serif', monospace;
}

*[class*='jss'],
*[class*='jss']:hover {
  color: unset !important;
  background-color: unset !important;
  /* z-index:auto ; */
}

*[class$='jss'],
*[class$='jss']:hover {
  color: unset;
  padding: unset;
  margin: unset;
  background-color: unset;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-by {
  min-width: 120px !important;
  font-size: 14px !important;
  text-align: center !important;
}

.update-by span {
  font-size: 12px !important;
}

.status {
  width: 160px;
}

pre.note{
  font-size: 16px;
      white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

/* .Toastify__toast-container{ */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  border-left: 5px solid var(--toastify-color-success) !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  border-left: 10px solid var(--toastify-color-error) !important;
  width: auto;
  z-index: 9999;
}

.container-card .card {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border: 1px solid #e5e5e5 !important;
}

/* Utility */
h5.required::after,
label.required::after {
  content: '*';
  color: red;
}

label.Mui-required .MuiInputLabel-asterisk {
  color: red;
}

.select-search__input {
  border: 1px solid #d3d3d4 !important;
}

/* typo */
.text-left {
  text-align: left;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* dropdown */
.dropdown .dropdown-item:not(.disabled):active {
  background-color: transparent;
  color: #212529;
}

/* modal */
.modal-backdrop.show {
  background-color: rgba(35, 31, 32, 1);
  opacity: 0.3;
  z-index: 2010;
}

.modal.show {
  z-index: 2015;
}

.modal-fullscreen,
.modal-fullscreen-sm-down,
.modal-fullscreen-md-down,
.modal-fullscreen-lg-down,
.modal-fullscreen-xxl-down {
  padding: 1rem !important;
}

.modal-promotion-type .modal-dialog{
  max-width: 450px !important;
}

.modal-bind-voucher-regsiter .modal-dialog{
  padding:24px;
  max-width: 1552px !important;
}

.modal-pop-view-promotion .modal-dialog{
  padding:0 24px;
  max-width: 1536px;
}

/* .text-nowrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
} */

/* custom scroll */
.custom-scroll {
  scrollbar-width: thin;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}

.custom-scroll-branch {
  scrollbar-width: thin;
}

.custom-scroll-branch::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.custom-scroll-branch::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}

.none-scroll {
  scrollbar-width: none;
}

.none-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}


.MuiDivider-fullWidth {
  border-color: rgb(94, 94, 94) !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}

/* TOQASTIFY */
.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  width: auto;
  z-index: 9999;
}

.ant-picker-dropdown {
  z-index: 2020;
}

.dropdown-item:hover {
  background-color: rgba(96, 96, 96, 0.06);
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

/* Chrome, Safari, Edge, Opera */
.no-up-down input::-webkit-outer-spin-button,
.no-up-down input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].no-up-down {
  -moz-appearance: textfield;
}

.btn-table-action:hover,
.btn-table-action:active,
.btn-table-action:focus {
  box-shadow: none !important;
}

.dropdown-table-action {
  border-color: #e5e5e5;
  border-radius: 3px;
}

.dropdown-table-transformNone {
  transform: none !important;
  inset: unset !important;
}

/* Tooltip */
.MuiTooltip-popper {
  z-index: 9999999 !important;
}

/* Loading */
.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top-color: rgba(56, 80, 128, 0.8);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spinner 1s linear infinite;
}

.loading-spinner-small {
  border-width: 2px;
  width: 1.5em;
  height: 1.5em;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1200px) {
  /* custom scroll */
  .custom-scroll::-webkit-scrollbar {
    height: 7px;
  }
}

.tooltip_2[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  top: auto;
  left: 158px;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  background-color: #444;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

@media (max-height: 450px), (max-width: 450px){
  .only.rmdp-month-picker, .only.rmdp-year-picker {
      height: 200px;
      width: 205px !important;
  }
}
.only.rmdp-month-picker, .only.rmdp-year-picker {
  width: 250px;
}

.card-title div .MuiAutocomplete-popper  {
  font-size: 16px !important;
  font-weight: 400 
}
:root {
  --theme-main-color: #2f4269;
  --theme-second-color: #1cb99a;
  --theme-third-color: #d8e8f4;
  --theme-fourth-color: #fb5252;
  --theme-fifth-color: #900c3f;
  --pale-blue: #d8e8f4;
  --theme-fight-color: #1cb99a;
  --theme-sixth-color: #ffeded;
  --theme-reset-color: #fc8f8f;
  --navbar-height: 70px;
  --sidebar-width: 70px;
}

/* FONT */
.fz-14 {
  font-size: 14px;
}
.fz-16 {
  font-size: 16px;
}
.fw-700 {
  font-weight: 700;
}
.fs-dot75rem {
  font-size: 0.75rem;
}

.fc-main {
  color: var(--theme-main-color);
}
.c-bbbbbb {
  color: #BBBBBB !important;
}

.link-simple {
  cursor: pointer;
  text-decoration: underline;
}
.text-nowrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wp-30 {
  width: 30px;
}
.wp-500 {
  width: 500px;
}

.hard-break {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
.cursor-pointer {
  cursor: pointer;
}

img.flip {
  transform: scaleX(-1);
}

/* LAYOUT */
@media (min-width: 1920px) {
  .order-3xl-1 {
    order: 1 !important;
  }
  .order-3xl-2 {
    order: 2 !important;
  }
  .col-3xl,
  .col-3xl-auto,
  .col-3xl-1,
  .col-3xl-2,
  .col-3xl-3,
  .col-3xl-4,
  .col-3xl-5,
  .col-3xl-6,
  .col-3xl-7,
  .col-3xl-8,
  .col-3xl-9,
  .col-3xl-10,
  .col-3xl-11,
  .col-3xl-12 {
    flex: 0 0 auto;
  }
  .col-3xl-auto {
    width: auto;
  }
  .col-3xl-1 {
    width: 8.33333333%;
  }
  .col-3xl-2 {
    width: 16.66666667%;
  }
  .col-3xl-3 {
    width: 25%;
  }
  .col-3xl-4 {
    width: 33.33333333%;
  }
  .col-3xl-5 {
    width: 41.66666667%;
  }
  .col-3xl-6 {
    width: 50%;
  }
  .col-3xl-7 {
    width: 58.33333333%;
  }
  .col-3xl-8 {
    width: 66.66666667%;
  }
  .col-3xl-9 {
    width: 75%;
  }
  .col-3xl-10 {
    width: 83.33333333%;
  }
  .col-3xl-11 {
    width: 91.66666667%;
  }
  .col-3xl-12 {
    width: 100%;
  }
  .mt-3xl-0 {
    margin-top: 0px !important;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: 'Sarabun', monospace, sans-serif;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.sm-data-table .MuiTableCell-root {
  padding: 2px 15px;
}

.btn-delete {
  background-color: #ff0000;
  color: #ffffff;
  cursor: 'pointer';
}

a.link-nav {
  color: #000000;
  text-decoration: unset;
}

a.link-nav:hover {
  text-decoration: underline;
}

.MuiFormControl-root {
  min-width: 200px;
}

.Toastify__toast {
  min-height: auto;
  min-width: 380px;
  mix-blend-mode: multiply;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.12);
  background-blend-mode: multiply;
  background: linear-gradient(to bottom, #fff, #fff);
  padding: 12px 16px;
  margin-right: 1rem;
}

.Toastify__toast-container {
  z-index: 2000;
}

.Toastify__toast-icon {
  margin-right: 16px;
  width: 24px;
}

.Toastify__close-button {
  line-height: 1.3;
  margin-left: 10px;
}

/* success */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  border-left: 4px solid #20a679 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #20a679;
}

/* error */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  border-left: 4px solid var(--toastify-color-error) !important;
}

/* info as warning */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--info {
  border-left: 4px solid #f88f41 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--info .Toastify__toast-icon svg {
  fill: #f88f41;
}

.Toastify__toast .Toastify__toast-body {
  margin: 0;
  padding: 0;
  color: #29302e;
}

.Toastify__toast .Toastify__progress-bar {
  height: 1px;
  opacity: 0 !important;
}

@media screen and (max-width: 576px) {
  .Toastify__toast {
    min-width: 100%;
    margin: 0;
  }

  .Toastify__toast-container {
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
  }
}

/* specific */
.Toastify__toast.register-page {
  margin-bottom: 72px;
}

.Toastify__toast.register-page + .Toastify__toast.register-page {
  margin-top: -56px;
}

/* case popup */
.Toastify__toast-container.Toast-popup {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transform: unset;
  padding: 0;
  width: auto;
}

.Toastify__toast-container.Toast-popup .Toastify__toast {
  margin: 8px 0 0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.Toastify__toast-container.Toast-popup .Toastify__close-button {
  color: #bcbec0;
  opacity: 1;
  line-height: normal;
}

.Toastify__toast-container.Toast-popup .Toastify__close-button svg {
  width: 20px;
  height: 20px;
}

/* toast popup custom */
.Toastify__toast-container.Toast-popup .toast-custom {
  cursor: default;
}

/* animate */
@keyframes slide_in {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide_out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.slide-in {
  animation: slide_in 0.3s ease-out both;
}

.slide-out {
  animation: slide_out 0.3s ease-in both;
}

.swal2-container {
  z-index: 2050 !important;
}

.swal-change-cost {
  z-index: 3000 !important;
}

/* backdrop */
.swal2-container.swal2-backdrop-show {
  background-color: rgba(35, 31, 32, 0.3) !important;
}

.swal2-container .swal2-popup {
  padding-bottom: 24px;
  -webkit-backdrop-filter: saturate(1) blur(40px);
          backdrop-filter: saturate(1) blur(40px);
  background-color: rgba(255, 255, 255, 0.9);
  width: auto;
  max-width: 576px;
  border-radius: 10px;
}

.swal2-container.swal2-warning .swal2-close {
  box-shadow: none !important;
  outline: none !important;
  font-size: 30px;
  color: #7070709c;
}

.swal2-container.swal2-warning .swal2-close:hover {
  color: #707070;
}

.swal2-container .swal2-icon.swal2-icon-show {
  margin: 24px auto;
}

.swal2-container .swal2-title {
  font-weight: 500;
  color: #29302e;
  padding: 0 24px;
  line-height: 1.3;
  font-size: 24px;
}

.swal2-container .swal2-html-container {
  font-size: 16px;
  color: #6d6e71;
  margin: 0;
  padding: 8px 24px 0;
  line-height: 1.5;
}

.swal2-container .swal2-actions {
  margin: 24px 0 0;
}

.swal2-container .swal2-actions button {
  margin: 0 8px;
  box-shadow: none !important;
  border: 1px solid;
}

/* swal2-cancel button */
.swal2-container .swal2-actions button.swal2-cancel {
  background-color: #ffffff;
  border-color: #929292;
  color: #29302e;
  height: 45px;
}

.swal2-container .swal2-actions button.swal2-cancel:hover {
  background: rgba(146, 146, 146, 0.03);
  border-color: #929292;
}

.swal2-container .swal2-cancelTreatmentQueue {
  background-color: #fb5252 !important;
  border-color: #fb5252 !important;
  color: #ffffff !important;
}

.custom-date-picker .ant-picker-panel-container {
  border-radius: 4px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin: 4px 0;
}

.custom-date-picker .ant-picker-header-super-prev-btn,
.custom-date-picker .ant-picker-header-super-next-btn,
.custom-date-picker .ant-picker-header-prev-btn,
.custom-date-picker .ant-picker-header-next-btn {
  color: #1cb99a;
}

.custom-date-picker button:hover {
  color: #1cb99a;
}

.custom-date-picker .ant-picker-today-btn {
  color: #1cb99a;
  text-decoration: none;
}

.custom-date-picker .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 4px;
  line-height: 20px;
  border: 1px solid transparent;
}

.custom-date-picker .ant-picker-cell-selected .ant-picker-cell-inner,
.custom-date-picker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #1cb99a;
}

.custom-date-picker .ant-picker-cell-today .ant-picker-cell-inner {
  border-color: #1cb99a;
}

.custom-date-picker .ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.custom-date-picker div.ant-picker-footer > a:hover {
  text-decoration: underline;
}

.custom-date-picker .ant-picker-dropdown-range {
  padding: 0;
}

/* custom-date-time-picker */
.custom-date-time-picker .ant-picker-footer .ant-picker-now-btn {
  color: #1cb99a;
  text-decoration: none;
}

.custom-date-time-picker .ant-picker-footer .ant-picker-now-btn:hover {
  text-decoration: underline;
}

/* .custom-time-picker */
.custom-time-picker .ant-picker-panel {
  width: 100%;
}

.custom-time-picker.input-time-picker {
  padding: 4px 0;
}

.custom-time-picker .ant-picker-footer {
  display: none;
}

/* custom-date-time-picker */
/* .custom-time-picker */
.custom-date-time-picker .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
.custom-time-picker .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  padding-left: 0 !important;
}

.custom-date-time-picker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.custom-time-picker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: rgba(28, 185, 154, 0.06) !important;
}

.custom-date-time-picker .ant-picker-time-panel .ant-picker-time-panel-column,
.custom-time-picker .ant-picker-time-panel .ant-picker-time-panel-column {
  text-align: center;
  overflow: scroll;
  scrollbar-width: thin;
}

.custom-date-time-picker .ant-picker-time-panel-column::-webkit-scrollbar,
.custom-time-picker .ant-picker-time-panel-column::-webkit-scrollbar {
  width: 2px;
}

.custom-date-time-picker .ant-picker-time-panel-column::-webkit-scrollbar-thumb,
.custom-time-picker .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}

.custom-date-time-picker .ant-picker-footer .ant-picker-ok button:not(:disabled),
.custom-time-picker .ant-picker-footer .ant-picker-ok button:not(:disabled) {
  background-color: #1cb99a;
  border-color: #1cb99a;
  border-radius: 4px;
}

.custom-date-time-picker .ant-picker-footer .ant-picker-ok button:disabled,
.custom-time-picker .ant-picker-footer .ant-picker-ok button:disabled {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  cursor: default;
}

/* Mui Lab */
/* custom-popup-date-picker */
.custom-popup-date-picker > .MuiPaper-root {
  border-radius: 4px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
}

.custom-popup-date-picker > .MuiPaper-root > div > div {
  width: auto;
}

.custom-popup-date-picker .MuiCalendarPicker-root {
  width: 280px;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type > div {
  font-size: 14px;
  color: #29302e;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root {
  border-radius: 4px;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root svg {
  font-size: 18px;
  fill: #29302e;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root.Mui-disabled svg {
  fill: #9e9e9e;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root {
  min-height: 170px;
  max-height: 240px;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .PrivatePickersSlideTransition-root {
  min-height: 210px;
  max-height: 250px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root > div {
  position: relative;
}

/* year */
.custom-popup-date-picker .MuiYearPicker-root .PrivatePickersYear-yearButton {
  font-size: 14px;
  width: auto;
  height: 26px;
  border-radius: 4px;
}

.custom-popup-date-picker .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected {
  background-color: #1cb99a;
}

/* month */
.custom-popup-date-picker .MuiMonthPicker-root {
  width: 280px;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root {
  height: 26px;
  margin: 8px 0;
  background-color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  color: #29302e !important;
  position: relative;
  z-index: 1;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root.Mui-selected {
  color: #fff !important;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
  border-radius: 4px;
  z-index: -1;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root:hover::before {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root.Mui-selected::before {
  background-color: #1cb99a;
}

/* range date picker */
.custom-range-date-picker-wrap > .MuiPaper-root {
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
}

.custom-range-date-picker-wrap > .MuiPaper-root ul {
  padding: 0;
}

.custom-range-date-picker-wrap > .MuiPaper-root ul > div {
  min-width: 280px;
  overflow-x: auto;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:first-of-type * {
  font-size: 14px;
  color: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:first-of-type svg {
  font-size: 18px;
  fill: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayPreview,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-root:first-of-type,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayPreview,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-root:last-of-type,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgba(28, 185, 154, 0.1);
}

/* date */
.custom-popup-date-picker .PrivatePickersFadeTransitionGroup-root .MuiTypography-caption,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiTypography-caption {
  width: 27px;
  height: 27px;
  font-size: 14px;
  margin: 0 4px;
  color: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiTypography-caption {
  width: 31px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today {
  background-color: #fff;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.Mui-disabled {
  color: #9e9e9e;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root {
  width: 27px;
  height: 27px;
  font-size: 14px;
  margin: 0 4px;
  color: #29302e;
  border-radius: 4px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root:hover,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today:hover {
  border: 1px solid #1cb99a;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root.MuiPickersDay-today {
  border-color: #1cb99a;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.Mui-selected,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #1cb99a;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #f5f5f5 !important;
}

.slick-default .slick-track {
  margin-left: 0 !important;
}

.slick-default .slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 1.5rem;
  line-height: 0;
}

.slick-default .slick-dots li {
  width: auto;
  height: auto;
  margin: 0 0.5rem;
}

.slick-default .slick-dots li.slick-active button {
  background-color: #29302e;
}

.slick-default .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #9e9e9e;
}

.slick-default .slick-dots li button:before {
  display: none;
}

.slick-default .slick-arrow {
  width: 35px;
  height: 35px;
  transform: translateY(-32px);
  background-color: #e5e5e5 !important;
  border-radius: 100px;
  text-align: center;
  z-index: 1;
}

.slick-default .slick-arrow:before {
  font-family: 'Material Icons', sans-serif !important;
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  opacity: 1;
}

.slick-default .slick-arrow.slick-prev {
  left: -10px;
}

.slick-default .slick-arrow.slick-prev:before {
  content: 'chevron_left';
}

.slick-default .slick-arrow.slick-next {
  right: -10px;
}

.slick-default .slick-arrow.slick-next:before {
  content: 'chevron_right';
}

.slick-default .slick-arrow.slick-disabled {
  visibility: hidden;
}

.slick-default .slick-next.slick-disabled + .slick-dots li:last-of-type button {
  background-color: #29302e;
}

@media screen and (max-width: 1199px) {
  .slick-default .slick-arrow.slick-prev {
    left: 0;
  }

  .slick-default .slick-arrow.slick-next {
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .slick-default .slick-dots {
    margin-top: 0.75rem;
  }

  .slick-default .slick-dots li {
    margin: 0 0.25rem;
  }

  .slick-default .slick-dots li button {
    width: 8px;
    height: 8px;
  }

  .slick-default .slick-arrow {
    display: none !important;
    visibility: hidden;
  }
}

#print-table-container {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media print {
  @page {
    /* size: landscape; */
    margin: 5mm;
    /* height: 90%; */
  }
  .private-route-container {
    visibility: hidden;
  }

  #print-table-container {
    visibility: visible ;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  #signature-button {
    border: transparent;
  }

  /* table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    page-break-inside: auto;
} */

tr {
    page-break-inside: avoid; /* ป้องกันไม่ให้แถวถูกตัดระหว่างหน้า */
}

thead {
    display: table-header-group; /* ทำให้ส่วนหัวซ้ำบนทุกหน้า */
}

tfoot {
    display: table-footer-group; /* ทำให้ส่วนท้ายซ้ำบนทุกหน้า */
}

/* บังคับ table 2 ให้ขึ้นหน้าใหม่ */
.table-2 {
    page-break-before: always; /* เริ่มหน้าใหม่ก่อนตารางนี้ */
    page-break-before: always;
    break-before: page; /* สำหรับเบราว์เซอร์ที่รองรับ break-* */
}

/* ขึ้นหน้าใหม่ เมืื่อหน้าไม่พอ */
.page-break-inside {
  display: block;
  page-break-inside: avoid;
}
 
}

body {
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column !important;
}

.root {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #f9fafc !important;
  font-size: 16px;
}

.ml {
  margin-left: 65px;
}

.container-menu {
  color: black;
  background-color: #f2f2f296 !important;
  z-index: 2000 !important;
}

.div-component {
  flex: 1 1;
  overflow: auto;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.h-vh-100 {
  height: 100vh;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.d-block {
  display: block;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.wp-150 {
  width: 150px !important;
}

*::selection {
  color: unset;
}

div[class$='jss'],
div[class$='jss']:hover {
  color: unset !important;
  background-color: unset !important;
}

.tooltip-graph-appointment {
  z-index: 1 !important;
}

.tooltip-graph-appointment .MuiTooltip-tooltip {
  color: #929292 !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
}

.slick-xray {
  padding-left: 1px;
  padding-right: 1px;
}

.slick-xray .slick-track {
  margin-left: 0 !important;
}

.slick-xray .slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 1.5rem;
  line-height: 0;
}

.slick-xray .slick-dots li {
  width: auto;
  height: auto;
  margin: 0 0.5rem;
}

.slick-xray .slick-dots li.slick-active button {
  background-color: #29302e;
}

.slick-xray .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #9e9e9e;
}

.slick-xray .slick-dots li button:before {
  display: none;
}

.slick-xray .slick-arrow {
  width: 32px;
  height: 32px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #c5c5c5 !important;
  border-radius: 100px;
  text-align: center;
  z-index: 1;
}

.slick-xray .slick-arrow.slick-prev {
  left: -11px;
}

.slick-xray .slick-arrow.slick-next {
  right: -11px;
}

.slick-xray .slick-arrow:before {
  font-family: 'Material Icons', sans-serif !important;
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-xray .slick-arrow.slick-prev:before {
  content: 'chevron_left';
}

.slick-xray .slick-arrow.slick-next:before {
  content: 'chevron_right';
}

.slick-xray .slick-arrow.slick-disabled {
  visibility: hidden;
}

.slick-xray .slick-next.slick-disabled + .slick-dots li:last-of-type button {
  background-color: #29302e;
}

@media screen and (max-width: 1199px) {
  .slick-xray .slick-arrow.slick-prev {
    /* left: -44px; */
  }

  .slick-xray .slick-arrow.slick-next {
    /* right: -44px; */
  }
}

@media screen and (max-width: 767px) {
  .slick-xray .slick-dots {
    margin-top: 0.75rem;
  }

  .slick-xray .slick-dots li {
    margin: 0 0.25rem;
  }

  .slick-xray .slick-dots li button {
    width: 8px;
    height: 8px;
  }
}

.print-show {
    display: none;
  }
  
  body > .print-show {
    display: block;
  }
  
  @media print {
    .print-show {
      display: block;
    }
  }

  .print-show-disable {
    display: block;
  }
  
  body > .print-show-disable {
    display: block;
  }
  
  @media print {
    .print-show-disable {
      display: none;
    }
  }
